exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-art-visualizer-js": () => import("./../../../src/pages/app/art-visualizer.js" /* webpackChunkName: "component---src-pages-app-art-visualizer-js" */),
  "component---src-pages-app-borastapeter-js": () => import("./../../../src/pages/app/borastapeter.js" /* webpackChunkName: "component---src-pages-app-borastapeter-js" */),
  "component---src-pages-app-byttapet-js": () => import("./../../../src/pages/app/byttapet.js" /* webpackChunkName: "component---src-pages-app-byttapet-js" */),
  "component---src-pages-app-ostermalm-champagne-js": () => import("./../../../src/pages/app/ostermalm-champagne.js" /* webpackChunkName: "component---src-pages-app-ostermalm-champagne-js" */),
  "component---src-pages-app-pj-vision-js": () => import("./../../../src/pages/app/pj-vision.js" /* webpackChunkName: "component---src-pages-app-pj-vision-js" */),
  "component---src-pages-app-roomblush-js": () => import("./../../../src/pages/app/roomblush.js" /* webpackChunkName: "component---src-pages-app-roomblush-js" */),
  "component---src-pages-app-spoken-picture-js": () => import("./../../../src/pages/app/spoken-picture.js" /* webpackChunkName: "component---src-pages-app-spoken-picture-js" */),
  "component---src-pages-app-spoken-pictures-js": () => import("./../../../src/pages/app/spoken-pictures.js" /* webpackChunkName: "component---src-pages-app-spoken-pictures-js" */),
  "component---src-pages-app-test-js": () => import("./../../../src/pages/app/test.js" /* webpackChunkName: "component---src-pages-app-test-js" */),
  "component---src-pages-appclip-borastapeter-js": () => import("./../../../src/pages/appclip/borastapeter.js" /* webpackChunkName: "component---src-pages-appclip-borastapeter-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ql-demo-js": () => import("./../../../src/pages/ql-demo.js" /* webpackChunkName: "component---src-pages-ql-demo-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-wall-art-visualizer-js": () => import("./../../../src/pages/wall-art-visualizer.js" /* webpackChunkName: "component---src-pages-wall-art-visualizer-js" */),
  "component---src-pages-wallpaper-visualizer-js": () => import("./../../../src/pages/wallpaper-visualizer.js" /* webpackChunkName: "component---src-pages-wallpaper-visualizer-js" */),
  "component---src-pages-web-visualizer-js": () => import("./../../../src/pages/web-visualizer.js" /* webpackChunkName: "component---src-pages-web-visualizer-js" */),
  "component---src-pages-wonderwall-borastapeter-privacy-policy-js": () => import("./../../../src/pages/wonderwall-borastapeter-privacy-policy.js" /* webpackChunkName: "component---src-pages-wonderwall-borastapeter-privacy-policy-js" */)
}

